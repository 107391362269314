@import "../../variables/variables";

.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.pages {
  display: flex;
  align-items: center;
}

.page {
  width: 32px;
  height: 32px;
  margin: 0 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $color-white;
  transition: .2s;
  border-radius: 4px;

  &:hover {
    background-color: $color-bg-2;
  }

  &_active {
    color: $color-bg-1;
    background-color: $color-primary;

    &:hover {
      background-color: $color-primary;
      opacity: 1;
    }
  }

  @media screen and (max-width: 340px) {
    margin: 0 1px;
  }
}

.left,
.right {
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: .2s;
  cursor: pointer;

  &:hover {
    & > svg > path {
      fill: $color-white;
      fill-opacity: 1;
    }
  }
}
