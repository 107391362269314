@import "../../variables/variables";

.info {
  margin-top: 43px;
  margin-bottom: 32px;
  color: $label-color-dark-secondary;

  &__content {
    padding: 40px;
    display: flex;
    justify-content: space-between;
  }

  &__left {
    display: flex;
  }

  &__avatar {
    width: 160px;
    height: 160px;
    margin-right: 32px;

    & > path {
      fill: $color-white-02;
    }
  }

  &__person {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
  }

  &__nick {
    color: $color-white;
  }

  &__verify-text {
    color: $color-primary;
  }

  &__right {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
  }

  &__changePswrd {
    color: $label-color-dark-secondary;

    &:hover {
      color: $color-hover;
    }
  }

  &__button {
    width: 147px;
    height: 44px;

    &_buy {
      margin-bottom: 16px;
      background-color: $color-primary;
    }

    &_sell {
      background-color: $color-white-02;
    }
  }

  @media screen and (max-width: 1024px) {
    margin-bottom: 16px;
  }

  @media screen and (max-width: 768px) {
    &__content {
      padding: 40px 24px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    &__left {
      width: 100%;
      flex-direction: column;
      align-items: center;
    }

    &__avatar {
      width: 80px;
      height: 80px;
      margin-right: 0;
      margin-bottom: 24px;
    }

    &__person {
      width: 100%;
      align-items: center;
    }

    &__nick {
      margin-bottom: 24px;
      text-align: center;
      font-size: 28px;
      line-height: 28px;
    }

    &__email {
      margin-bottom: 12px;
    }

    &__phone {
      margin-bottom: 12px;
    }

    &__verify {
      margin-bottom: 32px;
    }

    &__right {
      width: 100%;
      align-items: center;
    }

    &__buttons {
      width: 100%;
      margin-bottom: 32px;
    }

    &__button {
      width: 100%;
    }

    &__changePswrd {
      order: 10;
    }
  }
}

.account {
  margin-bottom: 60px;

  &__edit-btn {
    min-width: 56px;
    height: 56px;
    background-color: $color-white-01;
  }

  &__content {
    padding: 40px;
  }

  &__title {
    margin-bottom: 32px;
    color: $color-white;
  }

  &__bottom {
    display: flex;
  }

  &__block {
    margin-right: 24px;
  }

  &__input {
    max-width: 526px;
    width: 100%;
    height: 56px;
  }

  &__btn {
    width: 203px;
    height: 56px;
    background-color: $color-primary;
  }

  @media screen and (max-width: 1024px) {
    &__bottom {
      flex-direction: column;
    }

    &__input {
      max-width: none;
    }

    &__block {
      margin-bottom: 24px;
      margin-right: 0;
    }
  }

  @media screen and (max-width: 768px) {
    &__content {
      padding: 40px 24px;
    }

    &__btn {
      width: 100%;
    }
  }
}

.history {
  color: $color-dark-tertiary;

  &__top {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__export {
    width: 258px;
    height: 56px;
    background-color: $color-bg-3;
  }

  &__export_bottom {
    height: 0;
    opacity: 0;
    visibility: hidden;
  }

  &__table {
    margin-top: 46px;
    color: $label-color-dark-secondary;
  }

  &__head {
    padding-left: 26px;
    padding-right: 48px;
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-transform: uppercase;
  }

  &__numb {
    max-width: 650px;
    width: 100%;
  }

  &__time {
    max-width: 140px;
    width: 100%;
    margin-left: 0;
    margin-right: auto;
  }

  &__list {
    max-height: 332px;
    overflow: auto;

    &::-webkit-scrollbar-thumb {
      background-color: $color-dark-with-transparency;
    }

    &::-webkit-scrollbar {
      width: 4px;
      background-color: white;
      border-radius: 10px;
    }
  }

  &__row {
    padding: 20px 48px 20px 26px;
    margin-bottom: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: $color-bg-2;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__cell {
    &_time {
      color: $color-white;
    }

    &_sum {
      color: $color-primary;
    }

    &_minus {
      color: $default-system-red-dark;
    }
  }

  @media screen and (max-width: 1024px) {
    &__table {
      margin-top: 30px;
    }

    &__head {
      display: none;
    }

    &__row {
      padding: 16px;
      margin-bottom: 16px;
      flex-wrap: wrap;
    }

    &__cell {
      max-width: none;
      width: auto;
    }

    &__numb {
      max-width: none;
      width: 100%;
      margin-top: 12px;
      overflow: hidden;
      text-overflow: ellipsis;
      order: 10;
    }

    &__list {
      max-height: 490px;
    }
  }

  @media screen and (max-width: 768px) {
    margin-bottom: 34px;

    &__export {
      width: 100%;
      height: 56px;
      margin-top: 16px;
      display: flex;
      opacity: 1;
      visibility: visible;
    }

    &__export_top {
      height: 0;
      opacity: 0;
      visibility: hidden;
    }
  }
}

.phone {
  padding-bottom: 55px;
  color: $label-color-dark-secondary;

  &__content {
    padding-top: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__left {
    display: flex;
  }

  &__block {
    margin-right: 20px;
  }

  &__text {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
  }

  &__title {
    color: $color-white;
  }

  &__right {
    display: flex;
    align-items: center;
  }

  &__status {
    margin-right: 16px;
    transition: 0.2s;

    &_active {
      color: $color-primary;
    }
  }

  &__switch {
  }

  @media screen and (max-width: 768px) {
    &__status {
      display: none;
    }
  }

  @media screen and (max-width: 370px) {
    padding-bottom: 30px;

    &__content {
      padding-top: 0;
      flex-direction: column;
      align-items: flex-start;
      border: none;
    }

    &__left {
      margin-bottom: 20px;
    }

    &__right {
      align-self: center;
    }
  }
}

.off {
  margin-top: 250px;

  &__title {
    margin-bottom: 24px;
  }

  &__text {
    margin-bottom: 24px;
    color: $color-white-06;
  }

  &__btn {
    width: 100%;
    height: 56px;
    background-color: $color-primary;
  }
}

.on {
  &__title {
    margin-bottom: 24px;
  }

  &__text {
    margin-bottom: 24px;
    white-space: break-spaces;
    color: $color-white-06;
  }

  &__input {
    margin-bottom: 24px;
  }

  &__btn {
    width: 100%;
    height: 56px;
    background-color: $color-primary;
  }
}
