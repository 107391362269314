@import "src/variables/variables";

.header {
  border-bottom: 1px solid $color-white-01;
  color: $label-color-dark-secondary;

  @media screen and (max-width: 475px) {
    background-color: $color-main-bg;
  }

  &__content {
    height: 63px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__right {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__lk {
  }

  &__profile {
    margin: 0 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;

    @media screen and (max-width: 475px) {
      margin: 0 24px;
    }
  }

  &__profile-name {
    margin-left: 8px;

    @media screen and (max-width: 475px) {
      display: none;
    }
  }

  &__lang {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__lang-text {
    margin-right: 8px;
    // text-transform: uppercase;
    transition: 0.1s;

    &_active {
      color: $color-primary;
    }
  }

  &__lang-arrow {
    transition: 0.1s;
    & > path {
      transition: 0.1s;
    }

    &_active {
      transform: rotateX(180deg);
      & > path {
        fill: $color-primary;
        fill-opacity: 1;
      }
    }
  }

  &__lang-value {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
}

.lang {
  width: 105px;
  height: 80px;
  padding: 8px 0;
  position: absolute;
  left: -40px;
  top: calc(100% + 10px);
  background-color: $color-bg-1;
  border-radius: 8px;
  box-sizing: border-box;
  opacity: 0;
  visibility: hidden;
  transition: 0.1s;

  @media screen and (max-width: 1124px) {
    left: -68px;
  }

  &_active {
    opacity: 1;
    visibility: visible;
    z-index: 2;
  }

  &__apex {
    position: absolute;
    left: 50%;
    margin-left: -10px;
    top: -9px;

    @media screen and (max-width: 1124px) {
      left: auto;
      right: 0;
      top: -8px;
      margin-right: 18px;
    }
  }

  &__elem {
    width: 100%;
    height: 32px;
    padding-left: 11px;
    display: flex;
    align-items: center;
    color: $color-white;
    cursor: pointer;
    transition: 0.2s;

    &:hover {
      background-color: $color-bg-2;
    }

    &_active {
      background-color: $color-bg-2;
    }
  }
}

.select {
  &_active {
    left: 10px;
    z-index: 2;
    @media screen and (max-width: 1024px) {
      left: -60px;
    }
  }

  &-arrow {
    @media screen and (max-width: 768px) {
      display: none;
    }
  }
}
