@import "../../variables/variables";

.block {
  width: 56px;
  height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  background-color: $color-bg-2;

  @media screen and (max-width: 768px) {
    width: 44px;
    height: 44px;
  }

  & > svg {
    @media screen and (max-width: 768px) {
      width: 24px;
      height: 24px;
    }
  }
}
