@import "../../variables/variables";

.wrapper {
  display: flex;
  align-items: center;

  &__icon {
    margin-right: 20px;
  }

  &__text {
    color: $color-white;
  }

  @media screen and (max-width: 768px) {
    &__text {
      font-size: 20px;
      line-height: 28px;
    }

    &__icon {
      margin-right: 12px;
    }
  }
}
