@import "../../variables/variables";

.chart {
  &__x {
    stroke: $color-primary;
    fill: $color-primary;
  }

  &__y {
    margin-right: 20px;
  }

  &__dots {
    stroke: #3c3d55;
  }

  &__dot {
    width: 10px;
    height: 10px;
    border: 1px solid #fff;
  }
}

.dot {
  cursor: pointer;
}

.dot_active {
  cursor: pointer;
}

.tooltip {
  padding: 10px 20px 20px 20px;
  position: relative;
  background-color: $color-bg-1;
  border-radius: 8px;
  top: -150px;
  left: 200px;

  &__date {
    color: $color-primary;
  }

  &__coin {
    margin: 4px 0;
    color: $color-white;
  }

  &__usd {
    color: $color-white-06;
  }

  &::after {
    content: "";
    width: 20px;
    height: 20px;
    background-color: $color-bg-1;
    position: absolute;
    left: 50%;
    margin-left: -10px;
    bottom: -5px;
    transform: rotate(45deg);
  }
}
