@import "./../../variables/variables";

.card {
  padding: 24px;
  position: relative;
  border-radius: 20px;
  overflow: hidden;
  border: 2px solid $color-white-006;

  @media screen and (max-width: 520px) {
    background-color: $color-bg-2;
    border: none;
  }

  &__bg {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background: linear-gradient(
      96.49deg,
      rgba(255, 255, 255, 0.3) 0%,
      rgba(255, 255, 255, 0) 100%
    );
    backdrop-filter: blur(40px);

    @media screen and (max-width: 520px) {
      display: none;
    }
  }

  &__content {
    position: relative;
    display: flex;
    justify-content: space-between;

    @media screen and (max-width: 520px) {
      flex-direction: column;
    }
  }

  &__left {
    color: $color-white;
  }

  &__logo {
    margin-bottom: 9px;
  }

  &__title {
    margin-bottom: 8px;
    color: $color-white-05;
  }

  &__account {
    margin-bottom: 8px;
  }

  &__coin {
    margin-left: 8px;
  }

  &__money {
    color: $color-white-08;
  }

  &__course {
    position: absolute;
    right: 24px;
    bottom: 24px;
    color: $color-white-05;

    @media screen and (max-width: 520px) {
      top: 24px;
      right: 24px;
      left: auto;
      bottom: auto;
    }
  }
}
