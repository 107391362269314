@import "../../variables/variables";

.modal {
  margin-top: 40px;

  &__input {
    margin-bottom: 24px;

    &_mini {
      max-width: 288px;
      width: 100%;
    }

    &_email {
      margin-right: 24px;
    }
  }

  &__inputs {
    display: flex;
  }

  &__btn {
    max-width: 278px;
    width: 100%;
    height: 56px;
    margin: 0 auto;
    margin-bottom: 48px;
    background-color: $color-primary;
  }

  &__link {
    text-align: center;
  }

  @media screen and (max-width: 768px) {
    &__inputs {
      flex-direction: column;
    }

    &__input_mini {
      max-width: none;
    }
  }
}

.ready {
  margin-top: 40px;

  &__message {
    margin: 0 auto;
    margin-bottom: 24px;
    display: block;
  }

  &__subtitle {
    color: $color-white-06;
    text-align: center;
  }

  &__email {
    margin-bottom: 48px;
    color: $color-white;
    text-align: center;
  }

  &__link {
    margin-bottom: 48px;
    display: block;
    text-align: center;
  }

  &__support {
    text-align: center;
    color: $color-primary;
  }
}
