.checkbox {
  width: 18px;
  height: 18px;
  position: relative;
  display: block;
  border: 2px solid rgba(235, 235, 245, 0.6);
  box-sizing: border-box;
  border-radius: 3px;
  transition: 0.2s;
  box-sizing: content-box;
  cursor: pointer;

  &_active {
    border-color: transparent;
  }

  &__input {
    display: none;
  }

  &__icon {
    width: 18px;
    height: 18px;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    transition: 0.2s;

    &_show {
      opacity: 1;
    }
  }
}
