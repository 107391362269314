@import "../../variables/variables";

.modal {
  margin-top: 40px;

  &__input {
    margin-bottom: 24px;
  }

  &__btn {
    width: 100%;
    height: 56px;
    margin-bottom: 48px;
    background-color: $color-primary;
  }

  &__link {
    text-align: center;
  }
}
