@import "../../variables/variables";

.tabs {
  display: flex;
  align-items: center;
  position: relative;

  &::before {
    content: "";
    width: 100%;
    height: 1px;
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: $color-bg-2;
  }
}

.tab {
  height: 28px;
  margin-right: 40px;
  position: relative;
  color: $color-white-06;
  text-transform: uppercase;
  transition: .2s;

  &::after {
    content: "";
    width: 100%;
    height: 2px;
    position: absolute;
    left: 0;
    bottom: 0;
    background-color: $color-primary;
    border-radius: 8px;
    opacity: 0;
    transition: .2s;
  }

  &:last-child {
    margin-right: 0;
  }

  &_active {
    color: $color-white;

    &::after {
      opacity: 1;
    }
  }
}
