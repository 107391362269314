@import "../../variables/variables";

.modal {
  width: 100vw;
  max-height: 100vh;
  height: 100vh;
  padding-bottom: 20px;
  position: fixed;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  overflow: auto;
  transition: 0.3s;
  visibility: hidden;
  background-color: rgba(0, 0, 0, 0);
  z-index: 150;

  &_center {
    align-items: center;
  }

  &_active {
    visibility: visible;

    &.modal_dark {
      background-color: rgba(0, 0, 0, 0.5);
    }
  }

  &_relative {
    width: 100%;
    position: relative;
  }

  &__window {
    padding: 60px 40px;
    position: relative;
    background-color: $color-bg-2;
    box-shadow: 0 16px 40px rgba(0, 0, 0, 0.12);
    border-radius: 16px;
    transform: translateY(-150vh);
    transition: 0.3s;

    &_big {
      width: 680px;
    }

    &_medium {
      width: 420px;
    }

    &_active {
      transform: translateY(0);
    }
  }

  &__close {
    width: 22px;
    height: 22px;
    position: absolute;
    right: 25px;
    top: 25px;
  }

  &__logo {
    margin: 0 auto;
    margin-bottom: 60px;
    display: block;
  }

  &__title {
    margin-bottom: 48px;
    text-align: center;
    color: $color-white;
  }

  @media screen and (max-width: 768px) {
    align-items: flex-start;

    &__window {
      padding: 60px 24px;
      margin-top: 180px;
    }

    &__title {
      font-size: 28px;
      line-height: 28px;
    }
  }

  @media screen and (max-width: 720px) {
    &__window_big {
      width: calc(100vw - 32px);
    }
  }

  @media screen and (max-width: 450px) {
    &__window_medium {
      width: calc(100vw - 32px);
    }
  }

  @media screen and (max-width: 370px) {
    &__title {
      font-size: 25px;
    }
  }
}
