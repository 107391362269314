@import "../../variables/variables";

.block {
  margin-bottom: 24px;

  &_open {
    :global {
      .react-date-picker__calendar {
        display: unset;
      }
    }
  }

  :global {
    .react-calendar {
      background-color: $color-main-bg;
      color: $color-white;
    }

    .react-calendar__navigation__label {
      color: $color-white;

      &:hover {
        background-color: unset !important;
      }
    }

    .react-calendar__navigation__arrow {
      color: $color-white;

      &:hover,
      &:focus {
        background-color: unset !important;
      }
    }

    .react-calendar__tile {
      color: $color-white;

      &:hover {
        color: $color-main-bg;
      }
    }

    .react-calendar__month-view__days__day--weekend {
      color: $default-system-red-dark;
    }
  }
}

:global {
  .react-date-picker {
    display: block;
  }

  .react-date-picker__wrapper {
    border: none;
  }

  .react-date-picker__inputGroup {
    display: none;
  }

  .react-date-picker__calendar-button {
    width: 100%;
    padding: 0;

    &:hover {
      opacity: 1;
    }
  }
}
