@import "../../../variables/variables";

.modal {
  &__text {
    margin-bottom: 24px;
    text-align: center;
    color: $color-white-06;
  }

  &__input {
    margin-bottom: 24px;
  }

  &__btn {
    width: 100%;
    height: 58px;
    background-color: $color-primary;
  }

  &__link {
    font-family: $font-SFText;
    font-size: 15px;
    line-height: 20px;
    letter-spacing: -0.24px;
    color: $color-white-03;
  }

  &__link-text {
    font-family: $font-SFText;
    font-weight: 600;
    font-size: 15px;
    line-height: 20px;
    letter-spacing: -0.5px;
    color: $color-white-06;
  }
}
