@import "../../variables/variables";

.link {
  color: $color-white-06;
  transition: 0.2s;
  cursor: pointer;

  &:hover {
    color: $color-primary;
  }
}
