@import "../../variables/variables";

.account {
  display: flex;
  align-items: flex-start;
}

.balance {
  color: $color-white;
}

.coin {
  margin-left: 8px;
  color: $color-white-06;

  @media screen and (max-width: 335px) {
    font-size: 18px;
  }
}
