@import "../../variables/variables";

.wrapper {
  margin-bottom: 8px;
  padding: 21px 16px;
  display: flex;
  align-items: center;
  border-radius: 10px;
  background-color: $color-main-bg;
  box-sizing: border-box;
  border: 1px solid transparent;
  transition: 0.2s;
  cursor: text;

  &_focused {
    border: 1px solid $color-primary;
  }

  &_error {
    border: 1px solid $default-system-red-dark;
  }

  &_hold {
    background-color: $color-bg-2;
    border: 1px solid $color-bg-3;
  }

  &__icon {
    width: 24px;
    height: 24px;
    margin-right: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__right {
    height: 100%;
    position: relative;
    display: flex;
    flex: 1;
    align-items: flex-end;
    transition: 0.2s;

    &_focused {
      transform: translateY(5px);
    }
  }

  &__input {
    width: 100%;
    position: relative;
    font-family: $font-SFText;
    font-weight: normal;
    font-size: 17px;
    line-height: 22px;
    letter-spacing: -0.408px;
    color: $color-white;

    &:-webkit-autofill {
      -webkit-box-shadow: inset 0 0 0 50px $color-main-bg !important; /* Цвет фона */
      -webkit-text-fill-color: $color-white !important; /* цвет текста */
      color: $color-white !important; /* цвет текста */
    }

    &_hold {
      color: $color-dark-tertiary;
    }
  }

  &__placeholder {
    position: absolute;
    bottom: 0;
    left: 0;
    font-family: $font-SFText;
    font-weight: normal;
    font-size: 17px;
    line-height: 22px;
    letter-spacing: -0.408px;
    color: $color-dark-tertiary;
    transition: 0.2s;

    &_focused {
      transform: translateY(-22px);
      font-size: 11px;
      line-height: 17px;
      letter-spacing: 0.066px;
    }
  }

  @media screen and (max-width: 370px) {
    &__placeholder {
      font-size: 13px;

      &_focused {
        font-size: 11px;
      }
    }
  }
}

.error {
  margin: 8px 0;
  color: $default-system-red-dark;
}

.subscription {
  color: $label-color-dark-secondary;
}
