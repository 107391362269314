@import "../../variables/variables";

.typography {
}

.roboto-13 {
  font-family: $font-roboto;
  font-weight: 400;
  font-size: 13px;
  line-height: 140%;
}

.roboto-15 {
  font-family: $font-roboto;
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: -0.24px;
}

.roboto-17 {
  font-family: $font-roboto;
  font-size: 17px;
  line-height: 20px;
}

.roboto-20 {
  font-family: $font-roboto;
  font-weight: bold;
  font-size: 20px;
  line-height: 23px;
}

.roboto-24 {
  font-family: $font-roboto;
  font-size: 24px;
  line-height: 28px;
}

.montserrat-17 {
  font-family: $font-montserrat;
  font-weight: bold;
  font-size: 17px;
  line-height: 28px;
}

.montserrat-20 {
  font-family: $font-montserrat;
  font-weight: bold;
  font-size: 20px;
  line-height: 22px;
  letter-spacing: -0.408px;
}

.montserrat-22 {
  font-family: $font-montserrat;
  font-weight: bold;
  font-size: 22px;
  line-height: 28px;
  letter-spacing: 0.35px;
}

.montserrat-28 {
  font-family: $font-montserrat;
  font-weight: bold;
  font-size: 28px;
  line-height: 28px;
  letter-spacing: 0.35px;

  @media screen and (max-width: 475px) {
    font-size: 20px;
    line-height: 28px;
  }
}

.montserrat-36 {
  font-family: $font-montserrat;
  font-weight: bold;
  font-size: 36px;
  line-height: 44px;
}

.montserrat-44 {
  font-family: $font-montserrat;
  font-weight: bold;
  font-size: 44px;
  line-height: 54px;
  letter-spacing: 0.35px;

  @media screen and (max-width: 475px) {
    font-size: 28px;
    line-height: 28px;
  }
}

.sf-text-12 {
  font-family: $font-SFText;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
}

.sf-text-13 {
  font-family: $font-SFText;
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  letter-spacing: -0.078px;
}

.sf-text-15 {
  font-family: $font-SFText;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: -0.24px;
}

.sf-text-16 {
  font-family: $font-SFText;
  font-size: 16px;
  line-height: 21px;
  letter-spacing: -0.32px;
}

.sf-text-17 {
  font-family: $font-SFText;
  font-weight: 300;
  font-size: 17px;
  line-height: 22px;
  letter-spacing: -0.408px;
}

.sf-display-12 {
  font-family: $font-SFDisplay;
  font-weight: 500;
  font-size: 12px;
  line-height: 28px;
  letter-spacing: 0.35px;
}

.sf-display-22 {
  font-family: $font-SFDisplay;
  font-weight: 400;
  font-size: 22px;
  line-height: 28px;
  letter-spacing: 0.35px;
}
