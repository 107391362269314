@import "../../../variables/variables";

.modal {
  &__icon {
    margin: 0 auto;
    margin-bottom: 24px;
    display: block;
  }

  &__title {
    margin-bottom: 24px;
    text-align: center;
    color: $color-white;
  }

  &__text {
    margin-bottom: 24px;
    text-align: center;
    color: $color-white-06;
  }

  &__email {
    color: $color-white;
    text-align: center;
  }

  &__btn {
    width: 100%;
    height: 56px;
    background-color: $color-primary;
  }
}
