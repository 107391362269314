$label-color-dark-secondary: rgba(235, 235, 245, 0.6);
$color-dark-tertiary: rgba(235, 235, 245, 0.3);
$color-white: #ffffff;
$color-white-006: rgba(255, 255, 255, 0.06);
$color-white-01: rgba(255, 255, 255, 0.1);
$color-white-02: rgba(255, 255, 255, 0.2);
$color-white-03: rgba(255, 255, 255, 0.3);
$color-white-05: rgba(255, 255, 255, 0.5);
$color-white-06: rgba(255, 255, 255, 0.6);
$color-white-08: rgba(255, 255, 255, 0.8);
$color-gray: #93939d;
$color-main-bg: #1b1c30;
$color-bg-1: #404159;
$color-bg-2: #26273b;
$color-bg-3: #303149;
$color-bg-4: #373952;
$color-primary: #33cc66;
$color-green-dark: #2c7950;
$default-system-red-dark: #ff453a;
$color-dark-with-transparency: rgba(84, 84, 88, 0.65);
$color-dark-quaternary: rgba(235, 235, 245, 0.18);
$color-black-04: rgba(0, 0, 0, 0.4);
$color-hover: #3c6;

$font-roboto: "Roboto", sans-serif;
$font-montserrat: "Montserrat", sans-serif;
$font-SFDisplay: "SFDisplay", sans-serif;
$font-SFText: "SFText", sans-serif;
