@import "../../variables/variables";

.wrapper {
  position: relative;
  border: 1px solid transparent;
  border-radius: 10px;
  cursor: pointer;
  transition: 0.2s;

  &_open {
    border-color: $color-green-dark;
  }

  &__list {
    width: calc(100% + 2px);
    max-height: 180px;
    padding-bottom: 8px;
    position: absolute;
    top: 50px;
    left: -1px;
    background-color: $color-main-bg;
    border: 1px solid transparent;
    border-top: none;
    border-radius: 0 0 10px 10px;
    overflow: auto;
    z-index: 2;
    transition: 0.2s;
    opacity: 0;
    visibility: hidden;

    &_open {
      border-color: $color-green-dark;
      opacity: 1;
      visibility: visible;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $color-dark-with-transparency;
    }

    &::-webkit-scrollbar {
      width: 4px;
      background-color: white;
      border-radius: 10px;
    }
  }

  &__current {
    position: relative;
    cursor: pointer;

    &::after {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      cursor: pointer;
    }
  }

  &__elem {
    width: 100%;
    padding: 8px 10px 8px 58px;
    color: $color-white;
    cursor: pointer;
    transition: 0.2s;

    &:hover {
      background-color: $color-green-dark;
    }
  }

  &__arrow {
    & path {
      fill: $color-dark-quaternary;
    }
  }
}
