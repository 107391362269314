@import "../../../variables/variables";

.modal {
  width: 648px;

  &__date {
    margin-bottom: 16px;
    color: $color-white-05;
  }

  &__title {
    margin-bottom: 16px;
    color: $color-white;
  }

  &__text {
    font-family: $font-roboto;
    font-size: 20px;
    line-height: 23px;
    color: $color-white-08;
    font-weight: 400;
  }
}
