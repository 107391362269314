@import "../../variables/variables";

.modal {
  margin-top: 40px;

  &__phone {
    margin-bottom: 24px;
  }

  &__btn {
    width: 100%;
    height: 56px;
    background-color: $color-primary;
    color: $color-white;

    &:disabled {
      background-color: $color-green-dark;
      color: $color-gray;
    }
  }

  &__politic {
    display: flex;
    align-items: flex-start;
  }

  &__politic-right {
    margin-left: 19px;
    margin-bottom: 24px;
    color: $color-dark-tertiary;
  }

  &__bottom {
    margin-top: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $color-dark-tertiary;
  }

  &__link {
    margin-left: 4px;
  }

  &__edit {
    &:hover {
      & svg path {
        fill: $color-primary;
      }
    }
  }

  &__again {
    width: 100%;
    margin-top: 24px;
    text-align: center;
    color: $label-color-dark-secondary;
  }
}
