@import "../../variables/variables";

.modal {
  margin-top: 40px;

  &__input {
    margin-bottom: 24px;
  }

  &__btn {
    width: 100%;
    height: 58px;
    margin-bottom: 24px;
    background-color: $color-primary;
  }

  &__remember {
    margin-bottom: 48px;
    text-align: center;
    color: $color-dark-tertiary;

    &:hover {
      color: $color-hover;
    }
  }

  &__bottom {
    display: flex;
    justify-content: center;
    align-items: center;
    color: $color-dark-tertiary;
  }

  &__link {
    margin-left: 4px;
  }

  @media screen and (max-width: 360px) {
    &__bottom {
      flex-direction: column;
    }

    &__link {
      margin-top: 10px;
    }
  }
}

.verify {
  margin-top: 40px;

  &__subtitle {
    margin-bottom: 24px;
    text-align: center;
    color: $color-white-06;
  }

  &__input {
    margin-bottom: 24px;
  }

  &__btn {
    width: 100%;
    height: 58px;
    background-color: $color-primary;
  }
}
