@import "../../variables/variables";

.window {
  margin-top: 34px;
  padding-top: 49px;
  padding-bottom: 41px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: $color-dark-tertiary;

  &__title {
    margin-top: 19px;
    margin-bottom: 8px;
    color: $label-color-dark-secondary;
  }
}

