@import "../../variables/variables";

.footer {
  margin-bottom: 0;
  margin-top: auto;
  background-color: $color-bg-2;
  color: $color-white;

  &_high {
    position: relative;
  }

  @media screen and (max-width: 768px) {
    margin-top: 20px;

    &_high {
      margin-top: auto;
    }
  }

  &__content {
    height: 86px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media screen and (max-width: 768px) {
      height: auto;
      padding: 40px 0;
      flex-direction: column;
    }
  }

  &__left {
    display: flex;
    align-items: center;

    @media screen and (max-width: 768px) {
      flex-direction: column;
    }
  }

  &__subscription {
    margin-right: 32px;
    color: $color-white-06;

    @media screen and (max-width: 768px) {
      margin-right: 0;
    }
  }

  &__email {
    color: $color-primary;

    @media screen and (max-width: 768px) {
      margin-bottom: 24px;
    }
  }

  &__link {
    @media screen and (max-width: 768px) {
      margin-top: 20px;
      margin-bottom: 24px;
    }
  }
}

.telegram {
  width: 250px;
  height: 46px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $color-bg-4;
  border-radius: 8px;
  transition: 0.2s;

  &:hover {
    opacity: 0.8;
  }

  &__text {
    margin-left: 11px;
  }
}
