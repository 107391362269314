.container {
  max-width: 1032px;
  margin: 0 auto;
  padding: 0 32px;
  box-sizing: content-box;

  @media screen and (max-width: 1024px) {
    padding: 0 16px;
  }
}
