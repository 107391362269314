@import "src/variables/variables";

.button {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  color: $color-white;

  &:disabled {
    opacity: 0.5;
    cursor: auto;
  }
}
