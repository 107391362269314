* {
  padding: 0;
  margin: 0;
  outline: none;
  box-sizing: border-box;
}

a:focus,
.button:focus,
.button.alt:focus,
button:focus,
input[type="button"]:focus,
input[type="reset"]:focus,
input[type="submit"]:focus {
  outline: none;
}

li {
  list-style: none;
}

a {
  text-decoration: none;
  cursor: pointer;
  font-size: inherit;
  color: inherit;
  line-height: inherit;
  letter-spacing: inherit;
}

body {
  font-family: sans-serif;
  font-weight: normal;
  font-size: 16px;
}

button {
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
  transition: 0.2s;
}

button:hover {
  opacity: 0.8;
}

input {
  background: none;
  border: none;
}

.empty_relative {
  position: relative;
  min-height: 50px;
}

svg.recharts-surface {
  overflow: visible;
}
