@import "../../variables/variables";

.lk {
  margin-top: 30px;
}

.main {
  margin-bottom: 32px;
  color: $color-white;

  &__content {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media screen and (max-width: 780px) {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  &__right {
    display: flex;

    @media screen and (max-width: 780px) {
      width: 100%;
      margin-top: 25px;
      display: flex;
      justify-content: space-between;
    }
  }

  &__account {
    width: 454px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media screen and (max-width: 780px) {
      max-width: 500px;
      width: 100%;
      margin-right: 13px;
      overflow: hidden;
    }
  }

  &__numb {
    @media screen and (max-width: 780px) {
      width: 100%;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  &__name {
    color: $color-white-06;
  }

  &__btn {
    min-width: 44px;
    height: 42px;
    background-color: $color-white-01;
  }
}

.rate {
  margin-bottom: 24px;

  @media screen and (max-width: 1030px) {
    margin-bottom: 16px;
  }

  &__content {
    display: flex;
    justify-content: space-between;

    @media screen and (max-width: 1030px) {
      flex-direction: column;
    }
  }

  &__left {
    margin-right: 10px;

    @media screen and (max-width: 1030px) {
      margin-right: 0;
      margin-bottom: 16px;
    }
  }

  &__window {
    max-width: 506px;
    width: 100%;

    @media screen and (max-width: 1030px) {
      max-width: none;
    }
  }

  &__gtech-icon {
    position: absolute;
    top: 5px;
    right: -10px;
  }

  &__btn {
    width: 147px;
    height: 44px;

    @media screen and (max-width: 520px) {
      width: 100%;
    }
  }

  &__buy {
    margin-bottom: 16px;
    background-color: $color-primary;

    @media screen and (max-width: 520px) {
      margin-top: 25px;
    }
  }

  &__sell {
    background-color: $color-white-02;
  }

  &__eth-icon {
    position: absolute;
    top: 18px;
    right: 59px;

    @media screen and (max-width: 1030px) {
      top: 20px;
    }
  }
}

.info {
  margin-bottom: 64px;

  @media screen and (max-width: 475px) {
    margin-bottom: 40px;
  }

  &__content {
    display: flex;
    justify-content: space-between;

    @media screen and (max-width: 1030px) {
      flex-direction: column;
    }
  }

  &__left {
    max-width: 328px;
    width: 100%;
    padding: 20px;
    margin-right: 10px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    @media screen and (max-width: 1030px) {
      max-width: none;
      margin-bottom: 16px;
    }
  }

  &__prediction-title {
    max-width: 212px;
    margin-bottom: 21px;
    color: $color-white-08;
  }

  &__right {
    max-width: 680px;
    width: 100%;
    padding: 20px;
    display: flex;
    justify-content: space-between;
    overflow: hidden;

    @media screen and (max-width: 1030px) {
      max-width: none;
    }

    @media screen and (max-width: 648px) {
      flex-direction: column;
    }
  }

  &__power-title {
    margin-bottom: 41px;
    display: flex;
    align-items: center;
    color: $color-white-08;
  }

  &__power-icon {
    margin-right: 6px;
  }

  &__power-values {
    display: flex;
    align-items: center;
    position: relative;
  }

  &__power-left {
    margin-right: 44px;
  }

  &__freq {
    display: flex;
    align-items: flex-start;
  }

  &__freq-left {
    margin-right: 15px;

    @media screen and (max-width: 648px) {
      margin-top: 40px;
    }
  }

  &__freq-text {
    color: $label-color-dark-secondary;
    white-space: nowrap;

    &_top {
      margin-bottom: 51px;
    }

    &_right {
      margin-left: 112px;
    }
  }

  &__freq-right {
    margin-right: 11px;

    @media screen and (max-width: 648px) {
      margin-right: 0;
      margin-top: 6px;
    }
  }

  &__freq-dates {
    margin-right: 24px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  &__value {
    margin-bottom: 8px;

    &_usd {
      margin-top: 5px;
    }
  }

  &__money {
    color: rgba(255, 255, 255, 0.8);
    margin-bottom: 5px;
  }
}

.yield {
  overflow: hidden;

  &__title {
    margin-bottom: 24px;
  }

  &__content {
    height: 330px;
    padding-left: 16px;
    padding-bottom: 26px;
    padding-top: 48px;
    margin-bottom: 64px;

    &_chartik {
      // height: auto;
    }
  }

  &__tabs {
    margin-bottom: 24px;
  }
}

.payout {
  margin-bottom: 64px;

  &__title {
    margin-bottom: 24px;
  }

  &__tabs {
    margin-bottom: 24px;
  }

  &__content {
    height: 330px;
    margin-bottom: 65px;
    padding-left: 16px;
    padding-bottom: 26px;
    padding-top: 48px;
    // overflow: hidden;

    &_chartik {
      // height: auto;
    }

    & > div > div > svg {
      overflow: visible;
    }
  }

  &__heads {
    padding-left: 20px;
    padding-right: 38px;
    margin-bottom: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: $color-white-06;
  }

  &__wallet {
    width: 228px;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &__season {
    width: 128px;
    margin-right: 0;
    margin-left: auto;
  }

  // &__date {
  //   width: 108px;
  // }

  // &__payment {
  //   width: 107px;
  // }

  &__usd {
    width: 95px;
  }

  &__head {
    text-transform: uppercase;
  }

  &__row-left {
    max-width: 510px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__row-medium {
    max-width: 247px;
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  &__row-right {
    max-width: 130px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__medium {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__add {
    display: none;
    text-transform: uppercase;
  }

  &__body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    color: $color-white-06;
  }

  &__row {
    width: 100%;
    padding: 20px;
    padding-right: 38px;
    margin-bottom: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: $color-bg-2;
    border-radius: 8px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__cell {
    &.payout__date {
      color: $color-white;
    }

    &.payout__volume {
      color: $color-white;
    }

    &.payout__payment {
      color: $color-primary;
    }
  }

  &__pagination {
    margin-top: 32px;
  }

  @media screen and (max-width: 1024px) {
    &__heads {
      display: none;
    }

    &__row {
      padding: 16px;
      flex-wrap: wrap;
    }

    &__row-left {
      max-width: none;
      margin-bottom: 12px;
      flex-wrap: wrap;
    }

    &__cell {
      width: auto;
    }

    &__wallet {
      width: 100%;
      margin-top: 12px;
      order: 10;
    }

    &__season {
      margin: 0;
    }

    &__payment {
      margin-right: 8px;
    }

    &__add {
      display: block;
    }

    &__row-medium {
      max-width: none;
      margin-bottom: 8px;
      align-items: center;
    }

    &__medium {
      justify-content: flex-end;
    }

    &__row-right {
      max-width: none;
    }
  }

  @media screen and (max-width: 768px) {
    margin-bottom: 40px;
  }

  @media screen and (max-width: 335px) {
    &__add {
      font-size: 9px;
    }
  }
}

.profit {
  margin-bottom: 64px;

  &__title {
    margin-bottom: 24px;
  }

  &__content {
    padding: 40px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
  }

  &__info {
    display: flex;
    align-items: flex-start;
  }

  &__logo {
    margin-right: 25px;
  }

  &__subtitle {
    margin-bottom: 7px;
    color: $color-white-08;
  }

  &__eth {
    font-size: 20px;
    line-height: 23px;
    margin-bottom: 8px;
  }

  &__profit_money {
    color: rgba(255, 255, 255, 0.8);
  }

  &__button {
    width: 147px;
    height: 44px;

    &_buy {
      margin-bottom: 16px;
      background-color: $color-primary;
    }

    &_sell {
      background-color: $color-white-02;
    }
  }

  &__range {
    width: 100%;
    margin-top: 58px;
    margin-bottom: 40px;
    position: relative;
    display: flex;
    align-items: center;
    box-sizing: content-box;
  }

  &__help {
    display: flex;
    align-items: center;
    color: $color-white-05;
  }

  &__money {
    height: 30px;
    display: flex;
    align-items: center;
    color: $color-white-05;
  }

  &__mouse {
    margin-right: 7px;
  }

  &__track {
    width: 100%;
    height: 8px;
    background-color: $color-dark-with-transparency;
    z-index: 2;
  }

  &__track-left,
  &__track-right {
    position: absolute;
    height: 8px;
    top: 50%;
    margin-top: -4px;
  }

  &__track-left {
    left: 0;
    background-color: $color-primary;
  }

  &__track-right {
    right: 0;
    background-color: rgba(84, 84, 88, 0.65);
  }

  &__thumb {
    padding: 10px 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    box-shadow: 0 8px 24px #1b1c30;
    border-radius: 50px;
    background-color: $color-white;
  }

  &__ref {
    width: 100%;
    position: absolute;
    z-index: -1;
  }

  &__thumb-value {
    color: $color-bg-2;
  }

  &__thumb-item {
    color: $color-primary;
  }

  @media screen and (max-width: 768px) {
    margin-bottom: 40px;

    &__content {
      padding: 24px 24px 42px 24px;
      justify-content: center;
    }

    &__logo {
      min-width: 80px;
    }

    &__money {
      width: 100%;
      height: auto;
      justify-content: center;
      order: 10;
    }

    &__info {
      width: 100%;
      margin-bottom: 106px;
      order: 0;
    }

    &__range {
      margin-top: 0;
      margin-bottom: 40px;
      order: 1;
    }

    &__thumb {
      padding: 22px;
    }

    &__thumb-value {
      position: absolute;
      top: -48px;
      color: $color-white;
    }

    &__thumb-item {
      position: absolute;
      top: -26px;
    }

    &__buttons {
      width: 100%;
      margin-bottom: 16px;
      order: 2;
    }

    &__button {
      width: 100%;

      &_buy {
        margin-bottom: 24px;
      }
    }

    &__help {
      margin-bottom: 32px;
      order: 1;
    }
  }

  @media screen and (max-width: 500px) {
    &__info {
      flex-wrap: wrap;
    }
  }

  @media screen and (max-width: 370px) {
    &__logo {
      margin-right: 0;
      margin-bottom: 15px;
    }

    //&__info {
    //  flex-direction: column;
    //}
  }
}

.news {
  margin-bottom: 64px;

  &__content {
    position: relative;
  }

  &__title {
    margin-bottom: 24px;
  }

  &__subtitle {
    margin-bottom: 12px;
    color: $color-white;
    transition: 0.2s;
  }

  &__slide {
    max-width: 328px;
    width: 100%;
    border-radius: 16px;
    background-color: $color-bg-2;
    overflow: hidden;
    cursor: pointer;

    &:hover {
      & .news__subtitle {
        color: $color-primary;
      }
    }
  }

  &__img-wrapper {
    height: 164px;
    position: relative;

    &::after {
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      background-color: $color-primary;
      mix-blend-mode: multiply;
    }
  }

  &__img {
    width: 100%;
    height: 164px;
    object-fit: cover;
  }

  &__bottom {
    padding: 16px;
  }

  &__date {
    margin-bottom: 8px;
    color: $color-white-05;
  }

  &__line {
    width: 80px;
    height: 2px;
    margin-bottom: 12px;
    background-color: $color-primary;
  }

  &__text {
    font-family: $font-roboto;
    font-weight: 400;
    font-size: 13px;
    line-height: 140%;
    color: $color-white-08;
  }

  &__manage {
    width: 32px;
    height: 32px;
    top: 50%;
    margin-top: -16px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    background-color: $color-bg-2;
    border-radius: 50%;
    cursor: pointer;

    &_prev {
      left: -48px;
    }

    &_next {
      right: -48px;
    }
  }

  @media screen and (max-width: 1150px) {
    &__manage {
      width: 26px;
      height: 26px;

      &_prev {
        left: -29px;
      }

      &_next {
        right: -29px;
      }
    }
  }

  @media screen and (max-width: 1024px) {
    &__content {
      width: calc(100% - 46px);
      margin: 0 auto;
    }

    &__manage {
      &_prev {
        left: -35px;
      }

      &_next {
        right: -35px;
      }
    }
  }

  @media screen and (max-width: 768px) {
    margin-bottom: 50px;

    &__slide {
      margin: 0 auto;
    }
  }
}

.chartik {
  margin-top: 20px;
  margin-left: 8px;
  display: none;

  &__date {
    color: $color-primary;
  }

  &__eth {
    margin: 4px 0;
    color: $color-white;
  }

  &__usd {
    color: $label-color-dark-secondary;
  }

  @media screen and (max-width: 1024px) {
    display: block;
  }
}
