@import "src/variables/variables";

.app {
  background-color: $color-main-bg;

  &__bg {
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
  }

  &__content {
    min-height: 100vh;
    position: relative;
    display: flex;
    flex-direction: column;
  }

  &__loader {
    width: 100vw;
    height: 100vh;
    position: fixed;
    left: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 500;
    background-color: $color-black-04;
  }
}
